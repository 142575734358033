const ACF = '/acf'
const V1 = '/v1'
const V2 = '/v2'
const V3 = '/v3'
const WP = '/wp'
const WPJSON = '/wp-json'
const GRAVITY_FORMS = '/gf'
const YOAST = '/wp-rest-yoast-meta'

export const ENDPOINTS = {
  BUILDING_BLOCKS: {
    INDEX: `${WPJSON}${WP}${V2}/reise-baustein`,
    CATEGORIES: `${WPJSON}${WP}${V2}/building_block_categories`,
    SHOW: id => `${WPJSON}${WP}${V2}/reise-baustein${id ? `/${id}` : ``}`,
  },
  CATEGORIES: {
    INDEX: `${WPJSON}${WP}${V2}/categories`,
  },
  FORMS: {
    INDEX: `${WPJSON}${GRAVITY_FORMS}${V2}/public_forms`,
    SHOW: id =>
      `${WPJSON}${GRAVITY_FORMS}${V2}/public_forms${id ? `/${id}` : ``}`,
    SUBMIT: formId =>
      `${WPJSON}${GRAVITY_FORMS}${V2}/forms/${formId}/submissions`,
  },
  PAGES: {
    INDEX: `${WPJSON}${WP}${V2}/pages`,
    SHOW: id => `${WPJSON}${WP}${V2}/pages${id ? `/${id}` : ``}`,
  },
  POSTS: {
    INDEX: `${WPJSON}${WP}${V2}/posts`,
    SHOW: id => `${WPJSON}${WP}${V2}/posts${id ? `/${id}` : ``}`,
  },
  POST_CATEGORIES: {
    INDEX: `${WPJSON}${WP}${V2}/categories`,
    SHOW: id => `${WPJSON}${WP}${V2}/categories${id ? `/${id}` : ``}`,
  },
  ROUNDTRIPS: {
    INDEX: `${WPJSON}${WP}${V2}/rundreise`,
    CATEGORIES: `${WPJSON}${WP}${V2}/roundtrip_categories`,
    SHOW: id => `${WPJSON}${WP}${V2}/rundreise${id ? `/${id}` : ``}`,
    SHOW_CATEGORY: id =>
      `${WPJSON}${WP}${V2}/roundtrip_categories${id ? `/${id}` : ``}`,
  },
  SITES: {
    INDEX: `${WPJSON}${WP}${V2}/sites`,
  },
  TAXONOMIES: {
    INDEX: `${WPJSON}${WP}${V2}/taxonomies`,
  },
  THEMES: {
    INDEX: `${ACF}${V3}/options/theme-settings`,
  },
  TRAVELPLAN: {
    INDEX: `${WPJSON}${WP}${V2}/requests`,
  },
  PRODUCT_FILTERS: {
    INDEX: `${WPJSON}${WP}${V2}/product-filters`,
    SHOW: postType =>
      `${WPJSON}${WP}${V2}/product-filters${postType ? `/${postType}` : ``}`,
  },
  YOAST: {
    REDIRECTS: `${WPJSON}${YOAST}${V1}/redirects`,
  },
  TRAVEL_PLAN: {
    SHOW: ({ uid, travelPlanId }) =>
      `${WPJSON}${WP}${V2}/requests?uid=${uid}&travelplan=${travelPlanId}&minimum=1`,
  },
  DOTMAILER: {
    SUBMIT: `${WPJSON}${WP}${V2}/dotmailer`,
  },
  ROBOTS: {
    INDEX: '/index.php?robots=1',
  },
}
